<template>
  <v-row
    v-if="password.length >= 8"
    class="password-strength">
    <v-col
      v-for="i in 5"
      :key="i"
      class="pa-0">
      <div
        v-if="i > passwordStrength"
        class="password-level"/>
      <div
        v-else
        :class="`password-level ${passwordStrengthClass}`"/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CyPasswordStrength',
  props: {
    password: {
      type: String,
      default: '',
    },
  },
  computed: {
    $static: () => ({
      passwordLevels: [
        'red accent-2',
        'red accent-2',
        'orange darken-2',
        'yellow darken-2',
        'lime accent-4',
        'light-green accent-4',
      ],
    }),
    passwordStrength () {
      let score = 0
      if /* 10+ Chars    */ (this.password.length >= 10) score++
      if /* LowerCase    */ (this.password.match(/[a-z]/)) score++
      if /* UpperCase    */ (this.password.match(/[A-Z]/)) score++
      if /* Number       */ (this.password.match(/\d+/)) score++
      if /* Special Char */ (this.password.match(/.[!#$%&()*,?@^_~]/)) score++
      return score
    },
    passwordStrengthClass () {
      return this.$static.passwordLevels[this.passwordStrength]
    },
  },
  watch: {
    passwordStrength (newValue) {
      this.$emit('input', newValue)
    },
  },
  i18n: {
    messages: {
      en: {
        passwordLevelAverage: 'Average',
        passwordLevelStrong: 'Strong',
        passwordLevelWeak: 'Weak',
      },
      es: {
        passwordLevelAverage: 'Medio',
        passwordLevelStrong: 'Fuerte',
        passwordLevelWeak: 'Débil',
      },
      fr: {
        passwordLevelAverage: 'Moyen',
        passwordLevelStrong: 'Fort',
        passwordLevelWeak: 'Faible',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.password-strength {
  position: relative;
  top: -4px;
  margin-bottom: 8px;

  .password-level {
    height: 12px;
    margin-right: 5px;
    background-color: cy-get-color("grey", $alpha: 0.5);
  }
}
</style>
